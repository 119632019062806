@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  scroll-behavior: smooth;
}

/* Add these styles to your CSS file */
.no-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .text-stroke-orange {
    -webkit-text-stroke: 2px #ffe20a;
}

/* Add this to your global CSS file or Tailwind CSS file */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

body {
  scroll-behavior: smooth;
}


@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.float-animation {
  animation: float 3s ease-in-out infinite;
}


